<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="7" class="z-index-2">
          <v-card class="bg-transparent mb-6 px-4 py-4">
            <v-row>
              <v-col lg="6">
                <h2 class="text-h2 text-typo font-weight-bolder mb-0">
                  {{ 'Dashboard'|trans }}
                </h2>
              </v-col>
            </v-row>
          </v-card>
          <v-row>
            <v-col sm="5" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize
                        font-weight-bold
                        text-body
                      "
                    >
                      {{ 'Total requests' | trans }}
                    </p>
                    <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                      {{ summary.requestsCount }}
                      <span
                        class="text-success text-sm font-weight-bolder"
                        :title="'In the last 24 hours'|trans"
                        >+{{ summary.requestsInLast24h }}</span
                      >
                    </h5>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-default"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        fa fa-file-alt
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col sm="5" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize
                        font-weight-bold
                        text-body
                      "
                    >
                      {{ 'Total solved'|trans }}
                    </p>
                    <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                      {{ summary.requestsSolvedCount }}
                      <span
                        class="text-success text-sm font-weight-bolder"
                        :title="'In the last 24 hours'|trans"
                        >+{{ summary.requestsSolvedInLast24h }}</span
                      >
                    </h5>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-default"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        fa fa-smile-beam
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="10">
              <requests-by-country></requests-by-country>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <widget-globe></widget-globe>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import WidgetGlobe from "@/views/Dashboard/Widgets/Globe.vue";
import RequestsByCountry from "@/views/Dashboard/Widgets/RequestsByCountry";

export default {
  name: "Dashboard",
  components: {
    RequestsByCountry,
    WidgetGlobe,
  },
  data: function () {
    return {
      summary: {
        requestsCount: 0,
        requestsSolvedCount: 0,
        requestsInLast24h: 0,
        requestsSolvedInLast24h: 0,
      },
    };
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      fetch(process.env.VUE_APP_API_URL + "/reports/summary", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.authToken,
          "Accept-Language": this.$store.getters.getLocale,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to fetch summary"),
              text: data.message ? data.message : "",
            });

            return false;
          }

          this.summary = data.summary;
        })
        .catch((error) => {
          console.error("Error:", error);

          this.$notify({
            group: "global",
            type: "error",
            title: this.$translator.trans("Unable to fetch summary"),
            text: error.message ? error.message : "",
          });
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
  },
};
</script>
