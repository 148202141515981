<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-4 pt-4 pb-0">
      <p class="font-weight-bold text-h6 text-typo mb-0">{{ 'Requests by Country'|trans }}</p>
    </div>
    <v-card-text class="px-0 py-2">
      <v-list class="py-0">
        <v-list-item
          v-for="(country, index) in countries"
          :key="country.title"
          class="px-4 py-2"
          :class="{ 'border-bottom': index != countries.length - 1 }"
        >
          <country-flag :country="country.country" class="me-6" />

          <v-list-item-content>
            <span class="text-xs font-weight-bold text-body"> {{ 'Country'|trans }}: </span>
            <v-list-item-title
              class="text-typo font-weight-bold text-sm mb-0"
              >{{ country.country | countryName }}</v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-content class="text-center">
            <span class="text-xs font-weight-bold text-body"> {{ 'Total'|trans }}: </span>
            <v-list-item-title
              v-text="country.totalRequests"
              class="text-typo font-weight-bold text-sm mb-0"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="text-center">
            <span class="text-xs font-weight-bold text-body"> {{ 'Last 24h'|trans }}: </span>
            <v-list-item-title
              v-text="country.totalRequestsInLast24h"
              class="text-typo font-weight-bold text-sm mb-0"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="text-center">
            <span class="text-xs font-weight-bold text-body"> {{ 'Solved'|trans }}: </span>
            <v-list-item-title
              v-text="country.totalSolvedRequests"
              class="text-typo font-weight-bold text-sm mb-0"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import CountryFlag from "vue-country-flag";

export default {
  name: "requests-by-country",
  components: { CountryFlag },
  data() {
    return {
      countries: [],
      // countries: [
      //   {
      //     countryCode: "UA",
      //     country: "Ukraine",
      //     total: "2500",
      //     last24h: "230",
      //     solved: "2000",
      //   },
      //   {
      //     countryCode: "RO",
      //     country: "Romania",
      //     total: "2500",
      //     last24h: "230",
      //     solved: "2000",
      //   },
      //   {
      //     countryCode: "GB",
      //     country: "Great Britain",
      //     total: "2500",
      //     last24h: "230",
      //     solved: "2000",
      //   },
      //   {
      //     countryCode: "BR",
      //     country: "Brasil",
      //     total: "2500",
      //     last24h: "230",
      //     solved: "2000",
      //   },
      // ],
    };
  },
  mounted() {
    this.getRequestPerOriginCountry();
  },
  methods: {
    getRequestPerOriginCountry() {
      fetch(
        process.env.VUE_APP_API_URL + "/reports/requests-per-origin-country",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": this.authToken,
            "Accept-Language": this.$store.getters.getLocale,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to fetch summary"),
              text: data.message ? data.message : "",
            });

            return false;
          }

          this.countries = data.report;
        })
        .catch((error) => {
          console.error("Error:", error);

          this.$notify({
            group: "global",
            type: "error",
            title: this.$translator.trans("Unable to fetch summary"),
            text: error.message ? error.message : "",
          });
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
  },
};
</script>
